<template>
  <div class="aboutOurCompany">
    <div class="title"><h3>ابرز أعمال دعم النزاهة</h3></div>
    <v-divider></v-divider>
    <v-container>
      <v-row>
        <h4 class="blue--text">
          شركه مياه الشرب والصرف الصحي بأسيوط والوادي الجديد
        </h4>
        <span class="ml-2 mr-2">/</span
        ><span class="gray--text">أبرز أعمال دعم النزاهه</span>
      </v-row>
      <div id="app">
        <v-col class="subClass" cols="12" sm="12" xs="12" lg="12" md="12">
          <PDFJSViewer :path="`${path}`" :fileName="`${name2}`" />
        </v-col>
      </div>
      <div id="app">
        <v-col class="subClass" cols="12" sm="12" xs="12" lg="12" md="12">
          <PDFJSViewer :path="`${path}`" :fileName="`${name}`" />
        </v-col>
      </div>
      <v-row class="mt-12">
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel class="changeTitleStyleTrain">
            <v-expansion-panel-title
              expand-icon="mdi-menu-down"
              class="container"
            >
              <h4>
                <ul>
                  <li class="mr-10">
                    صور ورش العمل تطبيق الدليل التجارى الموحد
                  </li>
                </ul>
              </h4>
            </v-expansion-panel-title>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <v-row>
        <v-col
          cols="4"
          lg="4"
          md="12"
          sm="12"
          v-for="item in imagesCamp"
          :key="item"
        >
          <expandable-image
            class="Labimg scale-in-center mt-5"
            :src="item.img"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import PDFJSViewer from "../../components/PDFJSViewer.vue";
export default {
  name: "Anelsherka",
  components: {
    PDFJSViewer,
  },
  data() {
    return {
      name: "daamElnazaha.pdf", //change which pdf file loads
      path: "lib/web/viewer.html", //path of the PDF.js viewer.html
      name2: "achivement_daam_elnzaha.pdf",

      imagesCamp: [
        {
          img: require("@/assets/Images/daam_elnzaha/72.jpg"),
        },
        {
          img: require("@/assets/Images/daam_elnzaha/73.jpg"),
        },
        {
          img: require("@/assets/Images/daam_elnzaha/76.jpg"),
        },
        {
          img: require("@/assets/Images/daam_elnzaha/74.jpg"),
        },
        {
          img: require("@/assets/Images/daam_elnzaha/75.jpg"),
        },

        {
          img: require("@/assets/Images/daam_elnzaha/77.jpg"),
        },
      ],
    };
  },
  items() {},
};
</script>
<style scoped>
.Labimg {
  box-shadow: gray 3px 3px 3px 3px;
  border-image: 5px white;
}
.divider {
  width: 2px;
  background: #1f659b;

  height: 100%;
  margin-right: 10px !important;

  margin-top: 30px;
  margin-bottom: 30px;
}
.changeTitleStyleTrain {
  background: rgba(222, 184, 135, 0.192) !important;
  color: gray !important;
}
@media screen and (max-width: 1260px) {
  .divider {
    width: 0px !important;
  }
}
</style>
